import React from 'react';
import developerImg from '../images/developer_img.svg';
//import developerImg from '../images/myPic.jpeg';
import './Home.css';

const Home = () => {
  return (
    <div className="container">
      <div className="about">
        <div className="left-block">
          <div
            className="text-block"
            style={{ lineHeight: '1.7', marginLeft: '14px' }}
          >
            <div className="text-hello">Hello,</div>
            <div className="text-intro" style={{ marginTop: '10px' }}>
              I am Akshay H Mulky
            </div>
            <div className="text-role">Software Enthusiast</div>
            <div
              className="text-description"
              style={{ fontFamily: "'Roboto', sans-serif", marginTop: '10px' }}
            >
              I thrive on turning ideas into reality through the art of coding.
              Bringing digital concepts to life is where my passion meets
              purpose.
            </div>
          </div>
          <div className="button" style={{ marginTop: '20px' }}>
            <button className="resume">Download Resume</button>
          </div>
          <div
            className="links"
            style={{
              fontSize: '30px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              marginTop: '20px',
              color: 'inherit',
              backgroundColor: 'inherit',
            }}
          >
            <a href="https://github.com/" target="_blank">
              <i className="fa-brands fa-github"></i>
            </a>
            <a href="https://www.linkedin.com/in/" target="_blank">
              <i className="fa-brands fa-linkedin"></i>
            </a>
            <a href="https://www.hackerrank.com/" target="_blank">
              <i className="fa-brands fa-hackerrank"></i>
            </a>
          </div>
        </div>
        <div className="right-block">
          <img src={developerImg} alt="developer pic" />
        </div>
      </div>
    </div>
  );
};

export default Home;
