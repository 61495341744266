import React from 'react';
import Certificate from '../images/certificates/Servicenow_Certified_System_Administrator.png';
const SNOWCertificate = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <img
        style={{ maxWidth: '100%', height: 'auto' }}
        src={Certificate}
        alt="Servicenow Administrator"
      />
    </div>
  );
};

export default SNOWCertificate;
