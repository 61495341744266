import React from 'react';
import Certificate from '../images/certificates/Automation_Award.jpeg';
const SportAward = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <img
        style={{
          maxWidth: '100%',
          height: 'auto',
          padding: '10px 20px 10px 20px',
        }}
        src={Certificate}
        alt="Sport Award for Automation"
      />
    </div>
  );
};

export default SportAward;
