import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import RPA from './pages/RPA';
import SpanishCertificate from './pages/SpanishCertificate';
import SportAward from './pages/SpotAward';
import SNOWCertificate from './pages/SNOWCertificate';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="certificates/012c11d3-eedb-4781-b78e-b1b2f63109a4"
          element={<RPA />}
        />
        <Route
          path="certificates/6a55af60-4f1a-46d5-979c-ddbeb81959b5"
          element={<SpanishCertificate />}
        />
        <Route
          path="certificates/6fcb6958-cb56-48ff-8a59-2bc04c1271c5"
          element={<SportAward />}
        />
        <Route
          path="certificates/c539acc9-d895-4920-94b2-267a10022cac"
          element={<SNOWCertificate />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
